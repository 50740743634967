import React, { useEffect, useMemo, useState } from 'react';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import axios from 'axios';
import { API_URL } from '../../config';
import MultiSelectListbox from '../ListBoxes/MultiSelectListbox';
import { CollectionI } from '../../types/fetch/chatRoom';
import { MultiSelectListboxOptionI } from '../../types/ui/listBoxes';

interface CollectionsSelectListI {
    widthClass?: string,
    heightClass?: string,
    initialSelectedCollectionIds?: string[],
    listPosition?: 'top' | 'bottom',
    deselectAllInitially?: boolean,
    setSelectedCollectionIds: (collectionIds: string[]) => void
}

const CollectionsSelectList = (props: CollectionsSelectListI) => {

    const { widthClass = 'w-1/2', heightClass, initialSelectedCollectionIds, listPosition = 'top', deselectAllInitially = false, setSelectedCollectionIds } = props;

    const [isCollectionsLoading, setIsCollectionsLoading] = useState<boolean>(false);
    const [collections, setCollections] = useState<CollectionI[]>([]);
    const [selectedListboxOptions, setSelectedListboxOptions] = useState<MultiSelectListboxOptionI[]>([]);

    const listboxOptions: MultiSelectListboxOptionI[] = useMemo(() => {
        const mappedCollections = collections.map((collection: CollectionI) => {
            return { label: collection.name, value: collection.collection_id }
        })
        return mappedCollections;
    }, [collections]);

    const listboxLabel: string = useMemo(() => {
        return selectedListboxOptions.length === 0 ? 'Select Collections' : 'Selected Collections';
    }, [selectedListboxOptions]);

    useEffect(() => {
        const fetchCollections = async () => {
            setIsCollectionsLoading(true);
            try {
                const headers = await getAmplifySessionHeaders();
                const response = await axios.get(`${API_URL}/collections/list/`, { headers });
                setCollections(response.data);
            } catch (error) {
                console.error('Error fetching collections:', error);
            } finally {
                setIsCollectionsLoading(false);
            }
        };

        fetchCollections();
    }, []);

    useEffect(() => {
        const selectedCollectionIds = selectedListboxOptions.map((opt: MultiSelectListboxOptionI) => opt.value);
        setSelectedCollectionIds(selectedCollectionIds);
    }, [selectedListboxOptions, setSelectedCollectionIds]);

    useEffect(() => {
        if (initialSelectedCollectionIds && !isCollectionsLoading) {
            const matchedListboxOptions = listboxOptions.filter((opt: MultiSelectListboxOptionI) => initialSelectedCollectionIds.includes(opt.value))
            setSelectedListboxOptions(matchedListboxOptions);
        } else if (!initialSelectedCollectionIds && !isCollectionsLoading) {
            setSelectedListboxOptions(listboxOptions);
        }
    }, [initialSelectedCollectionIds, isCollectionsLoading, listboxOptions]);

    useEffect(() => {
        if (deselectAllInitially && !isCollectionsLoading) {
            setSelectedListboxOptions([]);
        }
    }, [deselectAllInitially, isCollectionsLoading, listboxOptions]);

    const handleSelectAll = () => {
        setSelectedListboxOptions(listboxOptions);
    }

    const handleDeselectAll = () => {
        setSelectedListboxOptions([]);
    }

    return (
        <div className={widthClass}>
            <MultiSelectListbox
                isLoading={isCollectionsLoading}
                label={listboxLabel}
                options={listboxOptions}
                listPosition={listPosition}
                stylingClasses='!bg-gray-50'
                optionsListStylingClasses={heightClass}
                selectedOptions={selectedListboxOptions}
                setSelectedOptions={setSelectedListboxOptions}
                handleSelectAll={handleSelectAll}
                handleDeselectAll={handleDeselectAll}
            />
        </div>
    )
}

export default CollectionsSelectList;
