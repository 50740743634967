import { useEffect, useMemo, useState } from 'react';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar';
import sensoLogoUrl from '../../images/senso-logo-with-text.png';
import { DEFAULT_DESIGNATION, DEFAULT_ONBOARDING_STEPS } from '../../constants/onboarding';
import GetStartedLeftComponent from './components/GetStarted/LeftComponent';
import GetStartedRightComponent from './components/GetStarted/RightComponent';
import SetupUserLeftComponent from './components/SetupUser/LeftComponent';
import SubmitConfirmationCodeLeftComponent from './components/SubmitConfirmationCode/LeftComponent';
import SetupPasswordLeftComponent from './components/SetupPassword/LeftComponent';
import SetupOrganizationLeftComponent from './components/SetupOrganization/LeftComponent';
import SetupOrganizationRightComponent from './components/SetupOrganization/RightComponent';
import InviteTeammatesLeftComponent from './components/InviteTeammates/LeftComponent';
import InviteTeammatesRightComponent from './components/InviteTeammates/RightComponent';
import UserProfile from './components/UserProfile';
import { OnboardingOrganizationI, OnboardingUserI, InviteDetailsI, OnboardingUserDetailsI, OnboardingProgressObjectKeysType, OnboardingProgressType } from '../../types/onboarding';
import defaultUserAvatarUrl from '../../images/default-user-avatar.png';
import defaultOrgAvatarUrl from '../../images/default-org-avatar.png';
import moment from 'moment';
import { InviteStatusEnum, InvitationStatusEnum, OnboardingStepsEnum, OnboardingTypesEnum, OnboardingProgessObjectKeysEnum } from '../../enums/onboarding';
import { cloneDeep } from 'lodash';
import axios from 'axios';
import { API_URL } from '../../config';
import BarLoader from 'react-spinners/BarLoader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_LOGIN, ROUTE_ONBOARDING, ROUTE_PROFILE } from '../../constants/routes';
import { StepI } from '../../types/ui/progressBar';
import InviteExpired from './components/InviteExpired';
import { getAmplifySessionHeaders } from '../../services/auth-service';
import OnboardigAlreadyCompleted from './components/OnboardigAlreadyCompleted';
import { Auth } from 'aws-amplify';
import { linkExistingCognitoUser, updateOnboardingUser } from '../../services/api/userAPI';
import { useAppDispatch } from '../../hooks/reduxHooks';
import useRoute from '../../hooks/useRoute';

const Onboarding = () => {

    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [steps, setSteps] = useState<StepI[]>([]);
    const [onboardingUserDetails, setOnboardingUserDetails] = useState<OnboardingUserDetailsI | undefined>(undefined);
    const [inviteDetails, setInviteDetails] = useState<InviteDetailsI | undefined>(undefined);
    const [onboardingProgress, setOnboardingProgress] = useState<OnboardingProgressType>({});
    const [invitationStatus, setInvitationStatus] = useState<`${InvitationStatusEnum}`>(InvitationStatusEnum.ONBOARDING_NOT_COMPLETED);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { getRoute } = useRoute();
    const { invite_id: inviteId } = useParams();

    const checkUserExistsInCognito = (details: InviteDetailsI) => {
        if (details.user) {
            return details.user.given_name !== null || details.user.family_name !== null
        } else {
            return false;
        }
    }

    const currentStepId: string = useMemo(() => {
        if (steps.length > 0) {
            return steps.find((step: StepI) => step.status === 'current')?.id ?? '';
        } else {
            return '';
        }
    }, [steps]);

    const isUserExistsInCognito: boolean = useMemo(() => {
        if (inviteDetails) {
            return checkUserExistsInCognito(inviteDetails);
        } else {
            return false;
        }
    }, [inviteDetails]);

    useEffect(() => {
        const fetchInviteDetails = async () => {
            setIsPageLoading(true);
            try {
                const headers = { 'Content-Type': 'application/json' };
                const response = await axios.get(`${API_URL}/users/invite/${inviteId}/`, { headers });
                if (response.data.invite_status === InviteStatusEnum.INVITED) {
                    if (checkUserExistsInCognito(response.data) && !location.state?.isFromLogin) {
                        navigate(ROUTE_LOGIN, { state: { onboardingInviteLink: ROUTE_ONBOARDING.replace(':invite_id', inviteId!), onboardingUserEmail: response.data.user!.email } });
                    } else {
                        setInviteDetails(response.data);
                        setInvitationStatus(InvitationStatusEnum.ONBOARDING_NOT_COMPLETED);
                    }
                } else if (response.data.invite_status === InviteStatusEnum.ACTIVE) {
                    setInvitationStatus(InvitationStatusEnum.ONBOARDING_COMPLETED);
                } else {
                    setInvitationStatus(InvitationStatusEnum.INVITATION_EXPIRED);
                }
            } catch (error) {
                console.error('Error fetching invite details:', error);
            } finally {
                setIsPageLoading(false);
            }
        };

        const logoutAndFetchInviteDetails = async () => {
            await Auth.signOut();
            dispatch({ type: 'RESET_STATE' });
            fetchInviteDetails();
        }

        if (location.state?.isFromLogin) {
            fetchInviteDetails();
        } else {
            logoutAndFetchInviteDetails();
        }
    }, []);

    useEffect(() => {
        if (inviteDetails) {
            const progressKeys: string[] = inviteDetails.onboarding_progress ? Object.keys(inviteDetails.onboarding_progress) : [];
            setSteps(getSteps(progressKeys));

            setOnboardingProgress(inviteDetails.onboarding_progress ?? []);
        }
    }, [inviteDetails]);

    useEffect(() => {
        if (inviteDetails) {
            const currentUtcTime = moment.utc(new Date()).format();

            const user: OnboardingUserI = {
                email: inviteDetails.user?.email ?? '',
                family_name: inviteDetails.user?.family_name ?? '',
                given_name: inviteDetails.user?.given_name ?? '',
                avatar: inviteDetails.user?.avatar ?? defaultUserAvatarUrl,
                created_at: isUserExistsInCognito ? inviteDetails.user!.created_at! : currentUtcTime,
                password: ''
            }

            const org: OnboardingOrganizationI = {
                ...inviteDetails.org,
                logo: inviteDetails.onboarding_type === OnboardingTypesEnum.ORG_AND_USER ? defaultOrgAvatarUrl : inviteDetails.org.logo,
                created_at: inviteDetails.onboarding_type === OnboardingTypesEnum.ORG_AND_USER ? currentUtcTime : '',
                role_name: DEFAULT_DESIGNATION,
                tier: ''
            }

            setOnboardingUserDetails({ user, org })
        }
    }, [inviteDetails]);

    const getSteps = (progressKeys: string[]) => {

        if (progressKeys.includes(OnboardingProgessObjectKeysEnum.ORG_SETUP_COMPLETE)) {
            const remainingStep: StepI = DEFAULT_ONBOARDING_STEPS.find((step: StepI) =>
                step.id === OnboardingStepsEnum.INVITE_TEAMMATES
            )!;
            return [{ ...remainingStep, status: 'current' }] as StepI[];
        } else if (progressKeys.includes(OnboardingProgessObjectKeysEnum.PASSWORD_AND_USER_SETUP_COMPLETE)) {
            const newSteps: StepI[] = DEFAULT_ONBOARDING_STEPS.filter((step: StepI) =>
                step.id === OnboardingStepsEnum.SETUP_ORGANIZATION || step.id === OnboardingStepsEnum.INVITE_TEAMMATES
            )
            return [{ ...newSteps[0], status: 'current' }, newSteps[1]] as StepI[];
        } else if (isUserExistsInCognito) {
            if (inviteDetails?.onboarding_type === OnboardingTypesEnum.USER_ONLY) {
                return DEFAULT_ONBOARDING_STEPS.filter((step: StepI) =>
                    step.id === OnboardingStepsEnum.GET_STARTED || step.id === OnboardingStepsEnum.SETUP_USER
                ) as StepI[];
            } else {
                return DEFAULT_ONBOARDING_STEPS.filter((step: StepI) =>
                    step.id !== OnboardingStepsEnum.SETUP_PASSWORD &&
                    step.id !== OnboardingStepsEnum.SUBMIT_CONFIRMATION_CODE
                ) as StepI[];
            }
        } else {
            if (inviteDetails?.onboarding_type === OnboardingTypesEnum.USER_ONLY) {
                return DEFAULT_ONBOARDING_STEPS.filter((step: StepI) =>
                    step.id !== OnboardingStepsEnum.SETUP_ORGANIZATION &&
                    step.id !== OnboardingStepsEnum.INVITE_TEAMMATES
                ) as StepI[];
            } else {
                return DEFAULT_ONBOARDING_STEPS as StepI[]
            }
        }
    }

    const updateOnboardingProgress = async (newProgressName: OnboardingProgressObjectKeysType) => {
        const newOnboardingProgress = { ...onboardingProgress, [newProgressName]: true };
        setOnboardingProgress(newOnboardingProgress);

        const headers = await getAmplifySessionHeaders();
        const reqBody = { onboarding_progress: newOnboardingProgress };
        try {
            await axios.post(`${API_URL}/users/invite/${inviteDetails!.invite_id}/progress/`, reqBody, { headers });
        } catch (error) {
            console.error('Error updating onboarding progress:', error);
        }
    }

    const completeOnboarding = async () => {
        const headers = await getAmplifySessionHeaders();
        try {
            await axios.post(`${API_URL}/users/invite/${inviteDetails!.invite_id}/complete/`, {}, { headers });
        } catch (error) {
            console.error('Error completing onboarding:', error);
        }
    }

    const handleGotoNextStep = async () => {

        if (isUserExistsInCognito && currentStepId === OnboardingStepsEnum.SETUP_USER) {
            await linkExistingCognitoUser(location.state?.cognitoId, onboardingUserDetails!.user.email, inviteDetails!.invite_id);
            await updateOnboardingUser({
                invite_id: inviteDetails!.invite_id,
                given_name: onboardingUserDetails!.user.given_name,
                family_name: onboardingUserDetails!.user.family_name,
                role_name: onboardingUserDetails!.org.role_name
            });
            await updateOnboardingProgress(OnboardingProgessObjectKeysEnum.PASSWORD_AND_USER_SETUP_COMPLETE);
        }
        else if (currentStepId === OnboardingStepsEnum.SUBMIT_CONFIRMATION_CODE) {
            await updateOnboardingProgress(OnboardingProgessObjectKeysEnum.PASSWORD_AND_USER_SETUP_COMPLETE);
        } else if (currentStepId === OnboardingStepsEnum.SETUP_ORGANIZATION) {
            await updateOnboardingProgress(OnboardingProgessObjectKeysEnum.ORG_SETUP_COMPLETE);
        } else if (currentStepId === OnboardingStepsEnum.INVITE_TEAMMATES) {
            await updateOnboardingProgress(OnboardingProgessObjectKeysEnum.INVITE_TEAMMATES_COMPLETE);
        }

        const currentStepIndex: number = steps.findIndex((step: StepI) => step.id === currentStepId);

        // If user is not in last step
        if (currentStepIndex !== steps.length - 1) {
            setSteps(prevSteps => {
                const clonedPrevSteps = cloneDeep(prevSteps);
                clonedPrevSteps.splice(currentStepIndex, 1, { ...clonedPrevSteps[currentStepIndex], status: 'complete' });
                clonedPrevSteps.splice(currentStepIndex + 1, 1, { ...clonedPrevSteps[currentStepIndex + 1], status: 'current' });
                return clonedPrevSteps;
            })
        } else {
            await completeOnboarding();
            dispatch({ type: 'RESET_STATE' });
            navigate(getRoute(ROUTE_PROFILE), { state: { isOnboardingSuccess: true } });
        }
    };

    const renderLeftComponent = () => {
        switch (currentStepId) {
            case OnboardingStepsEnum.GET_STARTED:
                return <GetStartedLeftComponent handleGotoNextStep={handleGotoNextStep} />
            case OnboardingStepsEnum.SETUP_USER:
                return <SetupUserLeftComponent onboardingUserDetails={onboardingUserDetails!} isGenericUser={inviteDetails?.is_generic ?? false} setOnboardingUserDetails={setOnboardingUserDetails} handleGotoNextStep={handleGotoNextStep} />
            case OnboardingStepsEnum.SETUP_PASSWORD:
                return <SetupPasswordLeftComponent onboardingUserDetails={onboardingUserDetails!} setOnboardingUserDetails={setOnboardingUserDetails} handleGotoNextStep={handleGotoNextStep} />
            case OnboardingStepsEnum.SUBMIT_CONFIRMATION_CODE:
                return <SubmitConfirmationCodeLeftComponent onboardingUserDetails={onboardingUserDetails!} inviteId={inviteDetails?.invite_id ?? ''} isGenericInvite={inviteDetails?.is_generic ?? false} handleGotoNextStep={handleGotoNextStep} />
            case OnboardingStepsEnum.SETUP_ORGANIZATION:
                return <SetupOrganizationLeftComponent onboardingUserDetails={onboardingUserDetails!} inviteId={inviteDetails?.invite_id ?? ''} setOnboardingUserDetails={setOnboardingUserDetails} handleGotoNextStep={handleGotoNextStep} />
            case OnboardingStepsEnum.INVITE_TEAMMATES:
                return <InviteTeammatesLeftComponent handleGotoNextStep={handleGotoNextStep} />
            default:
                return <></>
        }
    }

    const renderRightComponent = () => {
        switch (currentStepId) {
            case OnboardingStepsEnum.GET_STARTED:
                return <GetStartedRightComponent />
            case OnboardingStepsEnum.SETUP_USER:
            case OnboardingStepsEnum.SETUP_PASSWORD:
            case OnboardingStepsEnum.SUBMIT_CONFIRMATION_CODE:
                return <UserProfile onboardingUserDetails={onboardingUserDetails!} />
            case OnboardingStepsEnum.SETUP_ORGANIZATION:
                return <SetupOrganizationRightComponent onboardingUserDetails={onboardingUserDetails!} />
            case OnboardingStepsEnum.INVITE_TEAMMATES:
                return <InviteTeammatesRightComponent />
            default:
                return <></>
        }
    }

    const renderInvalidInvite = () => {
        return (
            <>
                {invitationStatus === InvitationStatusEnum.INVITATION_EXPIRED ?
                    <InviteExpired orgLogo={sensoLogoUrl} />
                    :
                    <OnboardigAlreadyCompleted orgLogo={sensoLogoUrl} />
                }
            </>
        );
    }

    return (
        <div className='w-full min-h-svh flex'>
            {isPageLoading ?
                <div className='w-full flex items-center justify-center'>
                    <BarLoader color={process.env.REACT_APP_PRIMARY_COLOR || "#00b894"} width="200" height="4" />
                </div>
                :
                <>
                    {invitationStatus === InvitationStatusEnum.ONBOARDING_NOT_COMPLETED ?
                        <div className='w-full flex flex-row'>
                            <div className="flex flex-col h-full lg:w-1/2 w-full p-12">
                                <div className='flex flex-col'>
                                    <div>
                                        <img src={sensoLogoUrl} alt='logo' className='sm:h-16 h-12' />
                                    </div>
                                    <div className='mt-12'>
                                        <ProgressBar steps={steps} />
                                    </div>
                                </div>
                                {onboardingUserDetails && renderLeftComponent()}
                            </div>
                            <div className="h-full w-1/2 lg:flex hidden items-end justify-end bg-gradient-to-r from-lighter-primary-color to-primary-color rounded-tl-3xl">
                                {onboardingUserDetails && renderRightComponent()}
                            </div>
                        </div>
                        :
                        <>
                            {renderInvalidInvite()}
                        </>
                    }
                </>
            }
        </div>
    );
}

export default Onboarding;