import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { HIDE_HEADER_FROM_ROUTES } from "../constants/mainLayout";
import { getUrlAfterRemovingQueryParams } from '../utils/urlHelpers';
import useRoute from '../hooks/useRoute';

const useLayout = () => {

    const location = useLocation();
    const { getRoute } = useRoute();

    const hideDefaultHeader: boolean = useMemo(() => {
        return HIDE_HEADER_FROM_ROUTES.map(route => {
            return (getUrlAfterRemovingQueryParams(getRoute(route)))
        }).includes(location.pathname) ||
            location.pathname.includes('/content/') //To hide header from collections page and content edit pages
    }, [location.pathname]);

    return { hideDefaultHeader };
};

export default useLayout;
