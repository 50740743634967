import { ROUTE_POSTFIX, ROUTE_PREFIX } from '../constants/routes';

export const getPathAfterRemovingPathParamAtEnd = (fullPath: string) => {
    return fullPath.substring(0, fullPath.lastIndexOf('/') + 1)
}

export const getRouteAfterRemovingQueryParams = (route: string): string => {
    return route.replace(ROUTE_POSTFIX, "")
}

export const getRouteAfterRemovingPrefix = (route: string): string => {
    return route.replace(ROUTE_PREFIX, "")
}

export const getUrlAfterRemovingQueryParams = (route: string): string => {
    return route.split('?')[0];
} 