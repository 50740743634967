import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SideBar.module.css';
import { Check, Settings, Building, LogOut, BarChart3, PieChart, Package2, LayoutDashboard, Server, SquareDashedBottomCode, FileCode } from 'lucide-react';
import { Auth } from 'aws-amplify';
import UserAvatar from '../../Avatars/UserAvatar';
import { useAppSelector, useAppDispatch } from "../../../hooks/reduxHooks";
import useRoute from '../../../hooks/useRoute';
import { ModuleEnum, ToolEnum } from '../../../enums/app';
import {
    ROUTE_LOGIN,
    ROUTE_SETTINGS,
    ROUTE_ECHO,
    ROUTE_ECHO_KNOWLEDGE,
    ROUTE_ECHO_METRICS,
    ROUTE_ECHO_COLLECTIONS,
    ROUTE_FETCH,
    ROUTE_FETCH_KNOWLEDGE,
    ROUTE_FETCH_METRICS_ANALYTICS,
    ROUTE_FETCH_CHAT_ROOM,
    ROUTE_FETCH_EVENTS,
    ROUTE_ECHO_EVENTS,
    ROUTE_INDEX_CHAT_ROOM,
    ROUTE_PROFILE,
    ROUTE_ORGANIZATION_SETTINGS,
    ROUTE_FETCH_COLLECTION_CONTENT,
} from "../../../constants/routes";
import { clearOrganizationState } from '../../../state/features/organization/organizationSlice';
import { clearUserState } from '../../../state/features/user/userSlice';
import { classNames } from '../../../utils/styleHelpers';
import { OrganizationI } from '../../../types/organization';
import ConfirmationModal from '../../Modals/ConfirmationModal';
import { Tooltip } from '../../Tooltip/Tooltip';
import SwitchOrgOption from './SwitchOrgOption';
import defaultNetworkLogoUrl from '../../../images/cu-copilot-logo.png';
import sensoNetworkLogoUrl from '../../../images/senso-logo-small.png';

const SideBar = () => {
    const navigate = useNavigate();
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [isSwitchOrgOptionActive, setIsSwitchOrgOptionActive] = useState<boolean>(false);
    const { getRoute } = useRoute();

    const userMenuRef = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();
    const { currentUser, firstCollection, isInOrgMode } = useAppSelector((state) => state.user);
    const selectedOrganization: OrganizationI | undefined = useAppSelector((state) => state.organization.selectedOrganization);
    const { currentModule, currentTool } = useAppSelector((state) => state.app);

    // set current network name depending on environment variable
    const currentNetworkLogoUrl = process.env.REACT_APP_UI_URL === 'https://platform.senso.ai' ? sensoNetworkLogoUrl : defaultNetworkLogoUrl;

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
                setShowUserMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [userMenuRef]);

    const navigateToKnowledge = () => {
        const path = getRoute(currentModule === ModuleEnum.ECHO ? ROUTE_ECHO_KNOWLEDGE : ROUTE_FETCH_KNOWLEDGE);
        navigate(path);
    };

    const navigateToMetrics = () => {
        const path = getRoute(currentModule === ModuleEnum.ECHO ? ROUTE_ECHO_METRICS : ROUTE_FETCH_METRICS_ANALYTICS);
        navigate(path);
    };

    const navigateToCollections = () => {
        const path =
            getRoute(currentModule === ModuleEnum.ECHO ? ROUTE_ECHO_COLLECTIONS : ROUTE_FETCH_COLLECTION_CONTENT,
                {
                    collection_id: firstCollection?.collection_id ?? 'undefined',
                    content_id: firstCollection?.first_document_id ?? 'undefined'
                }
            )
        navigate(path);
    };

    const navigateToChatRoom = () => {
        navigate(getRoute(ROUTE_FETCH_CHAT_ROOM));

    };

    const navigateToIndexChatRoom = () => {
        navigate(getRoute(ROUTE_INDEX_CHAT_ROOM));
    };

    const navigateToEvents = () => {
        const path = getRoute(currentModule === ModuleEnum.ECHO ? ROUTE_ECHO_EVENTS : ROUTE_FETCH_EVENTS);
        navigate(path);
    }

    const navigateToProfile = () => {
        navigate(getRoute(ROUTE_PROFILE))
    };

    const handleLogout = async () => {
        try {
            await Auth.signOut();
            navigate(ROUTE_LOGIN);
            dispatch(clearOrganizationState());
            dispatch(clearUserState());
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handleLogoutConfirmation = () => {
        setShowLogoutModal(false);
        handleLogout();
    };

    const ActiveFetchIcon = ({ color = "#39B88D" }) => (
        <svg className={styles.agentIcon} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.79161 8.34692H3.06482C2.22501 8.34692 1.80443 9.36229 2.39827 9.95613L7.51551 15.0734C7.6923 15.2502 7.93206 15.3495 8.18207 15.3495H12.5184C13.3582 15.3495 13.7788 14.3341 13.185 13.7403L7.79161 8.34692Z" fill={color} />
            <path fillRule="evenodd" clipRule="evenodd" d="M8.22548 1.7781C7.94768 1.5003 7.5709 1.34424 7.17803 1.34424H4.36526C3.04555 1.34424 2.38464 2.93982 3.31781 3.87299L7.79161 8.34679H11.218C12.5377 8.34679 13.1986 6.75121 12.2654 5.81804L8.22548 1.7781Z" fill={color} />
        </svg>
    );

    const ActiveEchoIcon = ({ color = "#39B88D" }) => (
        <svg className={styles.agentIcon} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_2915_183)">
                <path d="M15.518 8.20599C15.516 8.20412 15.5129 8.20426 15.5111 8.2063L10.0306 14.3732C9.66314 14.7621 9.04559 14.7796 8.6567 14.4121L6.22707 12.1164C6.02014 11.9209 6.01446 11.5982 6.20662 11.3948L10.2929 6.70329C10.4884 6.49636 10.4758 6.1738 10.2724 5.98165L8.86316 4.65008C8.65624 4.45456 8.65056 4.13181 8.84271 3.92845L9.85597 2.85607C10.2234 2.46719 10.841 2.44968 11.2299 2.81713L15.4826 6.83543C15.8703 7.20176 15.8889 7.81669 15.5249 8.20578C15.523 8.20772 15.52 8.20783 15.518 8.20599Z" fill={color} />
                <path d="M7.04923 7.00868C7.04726 7.00682 7.04415 7.0069 7.04229 7.00887L4.21463 10.0015C3.84719 10.3904 3.22963 10.4079 2.84075 10.0405L1.42774 8.77004C1.22081 8.57452 1.21513 8.25177 1.40728 8.0484L2.84075 6.5313C3.03627 6.32437 3.02366 6.00182 2.82029 5.80967L0.394415 3.45282C0.187486 3.2573 0.181809 2.93454 0.373959 2.73118L1.38722 1.65881C1.75466 1.26992 2.37222 1.25242 2.7611 1.61986L7.01385 5.63817C7.40154 6.00448 7.42013 6.61937 7.05617 7.00845C7.05432 7.01043 7.0512 7.01054 7.04923 7.00868Z" fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_2915_183">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );

    return (
        <div className={classNames('w-[250px] lg:w-[70px] bg-zinc-950', `${styles.sideBar}`)}>
            <ConfirmationModal iconConfig={{ icon: LogOut }} text={'Are you sure you want to log out?'} isModalOpen={showLogoutModal} confirmButtonConfig={{ text: 'Sign Out', onClick: handleLogoutConfirmation }} setIsModalOpen={setShowLogoutModal} />
            <div className={classNames('mt-[0px] lg:mt-[5px]', `${styles.topBar}`)}>
                <ul className={styles.navList}>
                    <Tooltip id="sidebar-tool-tip" />
                    <li className={
                        classNames(`${styles.navItem} lg:w-[34px] lg:h-[34px] flex justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`)}
                        onClick={navigateToProfile}
                    >
                        <img src={isInOrgMode ? selectedOrganization?.logo : currentNetworkLogoUrl} alt='logo' className='size-6 cursor-pointer rounded-[4px] object-fill' />
                    </li>
                    {currentModule === ModuleEnum.FETCH && isInOrgMode && <li className={
                        classNames(
                            currentTool === ToolEnum.CHAT_ROOM ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`,
                            //Temporary hide chat-room in mobile view
                            'hidden lg:flex',
                        )}
                        onClick={navigateToChatRoom}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Chat"
                    >
                        <LayoutDashboard />
                        <div className='text-base font-medium p-3 lg:hidden'>Chat Room</div>
                    </li>}
                    {currentModule === ModuleEnum.INDEX && <li className={
                        classNames(
                            currentTool === ToolEnum.CHAT_ROOM ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`,
                            //Temporary hide sidebar icon in mobile view
                            'hidden lg:flex',
                        )}
                        onClick={navigateToIndexChatRoom}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Chat"
                    >
                        <FileCode />
                    </li>}
                    {(currentModule === ModuleEnum.FETCH) && (currentUser?.ui_settings?.view_collections) && (<li className={
                        classNames(
                            currentTool === ToolEnum.COLLECTIONS ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`,
                            //Temporary hide collections in mobile view
                            'hidden lg:flex',
                        )}
                        onClick={navigateToCollections}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Collections"
                    >
                        <BarChart3 />
                        <div className='text-base font-medium p-3 lg:hidden'>Collections</div>
                    </li>)}
                    {(currentModule === ModuleEnum.ECHO) && isInOrgMode && (<li className={
                        classNames(
                            currentTool === ToolEnum.COLLECTIONS ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`,
                            //Temporary hide collections in mobile view
                            'hidden lg:flex',
                        )}
                        onClick={navigateToCollections}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Collections"
                    >
                        <BarChart3 />
                        <div className='text-base font-medium p-3 lg:hidden'>Collections</div>
                    </li>)}
                    {(currentModule !== ModuleEnum.INDEX) && (currentUser?.ui_settings?.show_documents) && isInOrgMode && (<li className={
                        classNames(
                            currentTool === ToolEnum.KNOWLEDGE ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] flex justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`
                        )}
                        onClick={navigateToKnowledge}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Knowledge"
                    >
                        <Package2 />
                        <div className='text-base font-medium p-3 lg:hidden'>Knowledge Base</div>
                    </li>
                    )}
                    {(currentModule !== ModuleEnum.INDEX) && (currentUser?.ui_settings?.show_analytics) && isInOrgMode && (<li className={
                        classNames(
                            currentTool === ToolEnum.METRICS ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] flex justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`
                        )}
                        onClick={navigateToMetrics}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Metrics"
                    >
                        <PieChart />
                        <div className='text-base font-medium p-3 lg:hidden'>Organization Metrics</div>
                    </li>
                    )}
                    {(currentModule === ModuleEnum.FETCH) && (currentUser?.ui_settings?.show_analytics) && isInOrgMode && (<li className={
                        classNames(
                            currentTool === ToolEnum.EVENTS ? `${styles.active}` : '',
                            `${styles.navItem} lg:w-[34px] lg:h-[34px] justify-start lg:justify-center mx-2 lg:mx-auto max-lg:px-2`,
                            //Temporary hide fetch events page in mobile view
                            'hidden lg:flex',
                        )}
                        onClick={navigateToEvents}
                        data-tooltip-id="sidebar-tool-tip"
                        data-tooltip-content="Events"
                    >
                        <Server />
                        <div className='text-base font-medium p-3 lg:hidden'>Events</div>
                    </li>)}
                </ul>
            </div>
            <div className={styles.bottomBar}>
                <ul className={styles.navList}>
                    <li className={classNames('justify-start px-5 lg:justify-center lg:px-0', `${styles.settingsNavItem}`)} onClick={() => setShowUserMenu(!showUserMenu)}>
                        <UserAvatar currentUser={currentUser} className={classNames('!size-8', currentUser?.user.avatar === null ? '!bg-primary-color' : '!bg-transparent')} initialsClassName='!text-xs text-white' />
                        <div className='lg:hidden text-base font-medium ml-[10px]'>{'Settings'}</div>
                    </li>
                    {showUserMenu && (
                        <div className={styles.userMenu} ref={userMenuRef} >
                            <div className={`${styles.menuItem} ${currentModule === ModuleEnum.FETCH ? styles.activeAgent : ""}`} onClick={() => { setShowUserMenu(false); navigate(getRoute(ROUTE_FETCH)) }} onMouseEnter={() => setIsSwitchOrgOptionActive(false)}>
                                {currentModule === ModuleEnum.FETCH ? <ActiveFetchIcon color={process.env.REACT_APP_PRIMARY_COLOR || "#00b894"} /> : <ActiveFetchIcon color={"#334155"} />}
                                Agent Fetch
                                <Check size="16" className={`${styles.checkIcon} ${currentModule === ModuleEnum.FETCH ? styles.activeCheckIcon : ""}`} />
                            </div>
                            {(currentUser?.ui_settings?.show_echo) && isInOrgMode && (
                                <div className={`${styles.menuItem} ${currentModule === ModuleEnum.ECHO ? styles.activeAgent : ""}`} onClick={() => { setShowUserMenu(false); navigate(getRoute(ROUTE_ECHO)) }} onMouseEnter={() => setIsSwitchOrgOptionActive(false)}>
                                    {currentModule === ModuleEnum.ECHO ? <ActiveEchoIcon color={process.env.REACT_APP_PRIMARY_COLOR || "#00b894"} /> : <ActiveEchoIcon color={"#334155"} />}
                                    Agent Echo
                                    <Check size="16" className={`${styles.checkIcon} ${currentModule === ModuleEnum.ECHO ? styles.activeCheckIcon : ""}`} />
                                </div>)}
                            {(selectedOrganization?.org_id == "ff74cc20-2baa-4eb9-9f07-c4717a40208a") && (<div className={`${styles.menuItem} ${currentModule === ModuleEnum.INDEX ? styles.activeAgent : ""}`} onClick={() => { setShowUserMenu(false); navigate(getRoute(ROUTE_INDEX_CHAT_ROOM)) }} onMouseEnter={() => setIsSwitchOrgOptionActive(false)}>
                                {currentModule === ModuleEnum.INDEX ? <SquareDashedBottomCode color={process.env.REACT_APP_PRIMARY_COLOR || "#00b894"} size={16} className='mr-[8px]' /> : <SquareDashedBottomCode color={"#334155"} size={16} className='mr-[8px]' />}
                                Agent Index
                                <Check size="16" className={`${styles.checkIcon} ${currentModule === ModuleEnum.INDEX ? styles.activeCheckIcon : ""}`} />
                            </div>)}
                            <hr></hr>
                            <div className={styles.menuItem} onClick={() => { setShowUserMenu(false); navigate(getRoute(ROUTE_SETTINGS)) }} onMouseEnter={() => setIsSwitchOrgOptionActive(false)}>
                                <Settings size="16" className={styles.otherIcon} />
                                My Settings
                            </div>
                            {(currentUser && currentUser.current_org.is_admin && <div className={styles.menuItem} onClick={() => { setShowUserMenu(false); navigate(getRoute(ROUTE_ORGANIZATION_SETTINGS)) }} onMouseEnter={() => setIsSwitchOrgOptionActive(false)}>
                                <Building size="16" className={styles.otherIcon} />
                                Org Settings
                            </div>)}
                            <SwitchOrgOption isSwitchOrgOptionActive={isSwitchOrgOptionActive} setIsSwitchOrgOptionActive={setIsSwitchOrgOptionActive} setShowUserMenu={setShowUserMenu} />
                            <div className={styles.menuItem} onClick={() => { setShowUserMenu(false); setShowLogoutModal(true) }} onMouseEnter={() => setIsSwitchOrgOptionActive(false)}>
                                <LogOut size="16" className={styles.otherIcon} />
                                Logout
                            </div>
                        </div>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default SideBar;