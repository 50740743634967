import React, { useEffect, lazy, Suspense } from 'react';
import './App.css';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { Auth } from 'aws-amplify';
import { getUserDetails } from './services/api/userAPI';
import { ToastContainer } from 'react-toastify';
import { useAppDispatch, useAppSelector } from "./hooks/reduxHooks";
import { setCurrentUser, setFirstCollection } from './state/features/user/userSlice';
import MainLayout from './components/Layout/MainLayout/MainLayout';
import { ErrorBoundary } from '@sentry/react'; // Import Sentry's ErrorBoundary
import * as Sentry from "@sentry/react";
import {
  ROUTE_ECHO_EVENTS, ROUTE_FETCH_EVENTS, ROUTE_LOGIN, ROUTE_PAGE_NOT_FOUND,
  ROUTE_PASSWORD_RESET, ROUTE_ONBOARDING,
  ROUTE_FETCH, ROUTE_FETCH_ROOT, ROUTE_INDEX_ROOT, ROUTE_ECHO_ROOT, ROUTE_PROFILE,
  ROUTE_NOTIFICATION_COMMENTS, ROUTE_SETTINGS, ROUTE_ORGANIZATION_SETTINGS
} from './constants/routes';
import { clearBreadcrumbPages } from './state/features/breadcrumb/breadcrumbSlice';
import { clearFetchEventsState } from './state/features/fetchEvents/fetchEventsSlice';
import Color from 'color';
import { getFirstCollection } from './services/api-service';
import Onboarding from './pages/Onboarding/Onboarding';
import { PUBLIC_ROUTES, PUBLIC_ROUTES_WITH_ONE_PATH_PARAM_AT_END } from './constants/app';
import { getPathAfterRemovingPathParamAtEnd, getRouteAfterRemovingQueryParams, getRouteAfterRemovingPrefix } from './utils/urlHelpers';
import awsconfig from './aws-exports';
import { getAmplifySessionHeaders } from "./services/auth-service";
import { API_URL } from "./config";
import { setAllOrganizations, setSelectedOrganization } from './state/features/organization/organizationSlice';
import { ROUTE_ECHO } from './constants/routes';
import useRoute from './hooks/useRoute';
import { ModeTypesEnum } from './enums/app';
import 'react-toastify/dist/ReactToastify.css';

const Profile = lazy(() => import('./pages/Profile/Profile'));
const NotificationComments = lazy(() => import('./pages/NotificationComments/NotificationComments'));
const SettingsPage = lazy(() => import('./pages/SettingsPage/SettingsPage'));
const OrganizationSettings = lazy(() => import('./pages/OrganizationSettings/OrganizationSettings'))
const ChatRoom = lazy(() => import('./pages/AgentFetch/ChatRoom'));
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'));
const LoginPage = lazy(() => import('./pages/LoginPage/LoginPage'));
const PasswordReset = lazy(() => import('./pages/PasswordReset/PasswordReset'));
const AgentIndex = lazy(() => import('./pages/AgentIndex/AgentIndex'));
const AgentEcho = lazy(() => import('./pages/AgentEcho/AgentEcho'));
const AgentFetch = lazy(() => import('./pages/AgentFetch/AgentFetch'));

Modal.setAppElement('#root');
Auth.configure(awsconfig);

const App = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getRoute } = useRoute();
  const lastModule = useAppSelector((state) => state.app.currentModule);
  const currentLoggedInUser = useAppSelector((state) => state.user.currentUser);
  const breadcrumbPages = useAppSelector((state) => state.breadcrumb.breadcrumbPages);
  const fetchEventsSelectedDateRange = useAppSelector((state) => state.fetchEvents.selectedDateRange);
  const fetchEventsSelectedSubCategory = useAppSelector((state) => state.fetchEvents.selectedEventSubCategoryName);
  const searchparams = new URLSearchParams(window.location.search);
  const newMode = searchparams.get('mode');

  const newOrgId = window.location.pathname.split('/')[2];

  const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || "#39B88D";
  const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || "#1d2345";
  const tertColor = process.env.REACT_APP_TERT_COLOR || secondaryColor;
  const loginTextColor = process.env.REACT_APP_LOGIN_TEXT_COLOR || '#475569';
  const chatIcon = process.env.REACT_APP_CHAT_ICON || 'https://public-styling-assets.s3.amazonaws.com/cucopilot/SensoLogo-1x.png';

  useEffect(() => {
    // Get the domain name
    const domain = window.location.hostname;

    let title;
    switch (domain) {
      case 'platform.cucopilot.com':
        title = 'CUCopilot';
        break;
      case 'fundmore.senso.ai':
        title = 'FundMore';
        break;
      case 'platform.senso.ai':
        title = 'Senso Platform';
        break;
      case 'truassist.trustone.org':
        title = 'TruAssist';
        break;
      case 'tribalscale.senso.ai':
        title = 'TribalScale';
        break;
      case 'beta.senso.ai':
        title = 'Senso Platform (Beta)';
        break;
      case 'localhost':
        title = 'Senso Platform (Local)';
        break;
      default:
        title = 'CUCopilot'; // Set a default title if domain doesn't match
    }

    // Set document title
    document.title = title;
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', primaryColor);
    document.documentElement.style.setProperty('--lighter-primary-color', Color(primaryColor).lightness(90).hex());
    document.documentElement.style.setProperty('--transparent-primary-color', Color(primaryColor).alpha(0.25));
    document.documentElement.style.setProperty('--secondary-color', secondaryColor);
    document.documentElement.style.setProperty('--tert-color', tertColor);
    document.documentElement.style.setProperty('--login-text-color', loginTextColor);
    document.documentElement.style.setProperty('--chat-icon', `url(${chatIcon})`);
  }, [primaryColor, secondaryColor, tertColor, loginTextColor, chatIcon]);

  const setFavicon = (faviconUrl) => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = faviconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  useEffect(() => {
    const faviconUrl = process.env.REACT_APP_FAVICON_URL;
    if (faviconUrl) {
      setFavicon(faviconUrl);
    }
  }, []);

  const fetchOrgs = async () => {
    const headers = await getAmplifySessionHeaders();
    try {
      const response = await fetch(`${API_URL}/users/orgs/`, {
        method: "GET",
        headers: headers
      });
      const data = await response.json();
      dispatch(setAllOrganizations(data));

      const currentOrg = data.find((org) => org.current);
      if (currentOrg) {
        dispatch(setSelectedOrganization(currentOrg))
      }
    } catch (error) {
      console.error('Error fetching organizations', error);
    }
  };

  const checkUserSession = async () => {
    const currentPath = window.location.pathname;
    try {
      await Auth.currentSession();
      if (!currentLoggedInUser) {
        const fetchedUser = await getUserDetails();
        const cognitoUser = await Auth.currentAuthenticatedUser();
        Sentry.setUser({
          id: cognitoUser.attributes.sub, // Use a unique identifier for the user
          email: fetchedUser.user.email, // Optionally include email or other identifiers
          // You can add more user details here as needed
        });
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "pab9a34q",
          name: fetchedUser.user.given_name + ' ' + fetchedUser.user.family_name, // Full name
          email: fetchedUser.user.email, // the email for your user
          user_id: cognitoUser.attributes.sub,
        });

        if (newOrgId !== undefined && newMode !== null) {
          await validateAndSwitchModeAndOrg(fetchedUser);
        } else {
          dispatch(setCurrentUser(fetchedUser));
        }
        await fetchOrgs();
        const firstCollection = await getFirstCollection();
        dispatch(setFirstCollection(firstCollection));

      } else {
        if (currentPath === "/login" || currentPath === "/signup" || currentPath === "/") {
          if (lastModule === 'echo') {
            navigate(getRoute(ROUTE_ECHO));
          } else {
            navigate(getRoute(ROUTE_FETCH));
          }
        }
      }

    } catch (error) {
      if (!PUBLIC_ROUTES.includes(currentPath) && !isPublicRoute(currentPath)) {
        sessionStorage.setItem('currentPath', JSON.stringify(currentPath));
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    checkUserSession();

    if (!location.pathname.includes(getRouteAfterRemovingQueryParams(getRouteAfterRemovingPrefix(ROUTE_ECHO_EVENTS)))
      && !location.pathname.includes(getRouteAfterRemovingQueryParams(getRouteAfterRemovingPrefix(ROUTE_FETCH_EVENTS)))
      && breadcrumbPages.length > 0) {
      dispatch(clearBreadcrumbPages());
    }

    if ((fetchEventsSelectedDateRange || fetchEventsSelectedSubCategory) &&
      !location.pathname.includes(getRouteAfterRemovingQueryParams(getRouteAfterRemovingPrefix(ROUTE_FETCH_EVENTS)))) {
      dispatch(clearFetchEventsState());
    }
  }, [location.pathname, currentLoggedInUser]);

  const isPublicRoute = (currentPath) => {
    return !!PUBLIC_ROUTES_WITH_ONE_PATH_PARAM_AT_END.find((route) => getPathAfterRemovingPathParamAtEnd(route) === getPathAfterRemovingPathParamAtEnd(currentPath));
  }

  const changeMode = async () => {
    const headers = await getAmplifySessionHeaders();
    const reqBody = { mode: newMode };

    await fetch(`${API_URL}/users/mode/`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(
        reqBody
      ),
    });

    const fetchedUser = await getUserDetails();
    dispatch(setCurrentUser(fetchedUser));
  }

  const handleOrgChange = async (orgId) => {
    const headers = await getAmplifySessionHeaders();
    try {
      await fetch(`${API_URL}/users/orgs/`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({
          org_id: orgId,
        }),
      });

      const fetchedUser = await getUserDetails();
      dispatch(setCurrentUser(fetchedUser));

    } catch (error) {
      // redirect to not found page
      navigate(getRoute(ROUTE_PAGE_NOT_FOUND));
      console.error('Error changing organization', error);
    }
  };

  const validateAndSwitchModeAndOrg = async (fetchedUser) => {

    const currentMode = (fetchedUser.ui_settings.is_org_mode ? ModeTypesEnum.ORG_LEVEL : ModeTypesEnum.NERWORK_LEVEL).toString();
    const currentOrgId = fetchedUser.current_org.org_id;

    if (currentOrgId === newOrgId && currentMode === newMode) {
      dispatch(setCurrentUser(fetchedUser));
    } else {
      if (currentOrgId !== newOrgId) {
        await handleOrgChange(newOrgId);
      }
      if (currentMode !== newMode) {
        await changeMode();
      }
    }
  }

  if (!PUBLIC_ROUTES.includes(window.location.pathname) && !currentLoggedInUser) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<div>An unexpected error has occurred, please refresh the page.</div>}>
      <div className="mainAppContainer">
        <ToastContainer />
        <Routes>
          <Route element={<MainLayout />}>
            <Route path={`/${getRouteAfterRemovingQueryParams(ROUTE_PROFILE)}`} element={
              <Suspense fallback={<></>}>
                <Profile key={location.key} />
              </Suspense>
            } />
            <Route path={`/${getRouteAfterRemovingQueryParams(ROUTE_NOTIFICATION_COMMENTS)}`} element={
              <Suspense fallback={<></>}>
                <NotificationComments key={location.key} />
              </Suspense>
            } />
            <Route exact path={`/${getRouteAfterRemovingQueryParams(ROUTE_SETTINGS)}`} element={
              <Suspense fallback={<></>}>
                <SettingsPage />
              </Suspense>
            } />
            <Route exact path={`/${getRouteAfterRemovingQueryParams(ROUTE_ORGANIZATION_SETTINGS)}`} element={
              <Suspense fallback={<></>}>
                <OrganizationSettings key={location.key} />
              </Suspense>
            } />
            <Route path={`${ROUTE_ECHO_ROOT}/*`} element={
              <Suspense fallback={<></>}>
                <AgentEcho />
              </Suspense>
            } />
            <Route path={`/${ROUTE_FETCH_ROOT}/*`} element={
              <Suspense fallback={<></>}>
                <AgentFetch />
              </Suspense>
            } />
            <Route path={`/${ROUTE_INDEX_ROOT}/*`} element={
              <Suspense fallback={<></>}>
                <AgentIndex />
              </Suspense>
            } />
            <Route exact path="/chat" element={
              <Suspense fallback={<></>}>
                <ChatRoom />
              </Suspense>
            } />
            <Route exact path={ROUTE_PAGE_NOT_FOUND} element={
              <Suspense fallback={<></>}>
                <PageNotFound />
              </Suspense>
            } />
          </Route>
          <Route exact path={ROUTE_LOGIN} element={
            <Suspense fallback={<></>}>
              <LoginPage />
            </Suspense>
          } />
          <Route exact path={ROUTE_PASSWORD_RESET} element={
            <Suspense fallback={<></>}>
              <PasswordReset />
            </Suspense>
          } />
          <Route exact path={ROUTE_ONBOARDING} element={<Onboarding />} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
};

export default App;
