import { HeaderSegmentedControlConfigI, HeaderIconBtnsConfigI } from "../types/ui/header";
import { CirclePlus, FilePenLine, FolderPlus, PlayCircle, Plus, Share2, SlidersHorizontal, Zap, History, MessageSquare, LayoutList } from 'lucide-react';
import {
  ROUTE_ECHO_COLLECTION, ROUTE_ECHO_KNOWLEDGE, ROUTE_ECHO_METRICS, ROUTE_FETCH_KNOWLEDGE, ROUTE_FETCH_CHAT_ROOM, ROUTE_INDEX_CHAT_ROOM,
  ROUTE_ECHO_TAGS, ROUTE_FETCH_TAGS, ROUTE_FETCH_KNOWLEDGE_CONTENT_CREATE, ROUTE_PROFILE, ROUTE_FETCH,
} from '../constants/routes';
import { useAppDispatch } from "./reduxHooks";
import { setIsContentMenuOpen, setIsAddNewCategoryBtnClicked as setIsFetchAddNewCategoryBtnClicked, setIsUploadModalOpen } from "../state/features/fetchKnowledge/fetchKnowledgeSlice";
import { setIsContentMenuOpen as setIsProfileContentMenuOpen, setIsUploadModalOpen as setIsProfileUploadModalOpen } from "../state/features/profile/profileSlice";
import { useAppSelector } from "./reduxHooks";
import { IconButtonI } from "../types/ui/iconButton";
import { useMemo } from "react";
import { setIsFilterModalOpen, setIsSaveModalOpen } from "../state/features/agentEcho/agentEchoSlice";
import { InteractionCollectionI } from "../types/echo/collections";
import { AdminRolesEnum } from "../enums/userRoles";
import { setIsRunModalOpen, setIsShareModalOpen as setIsEchoCollectionsShareModalOpen } from "../state/features/echoCollections/echoCollectionsSlice";
import { setIsNewSession } from "../state/features/fetchChatRoom/fetchChatRoomSlice";
import { setIsSavedQueriesPanelOpen } from "../state/features/indexChatRoom/indexChatRoomSlice";
import { setIsAddNewCategoryBtnClicked as setIsEchoAddNewCategoryBtnClicked } from "../state/features/echoKnowledge/echoKnowledgeSlice";
import AdvancedMenu from "../components/Menus/AdvancedMenu";
import { ADD_CONTENT_MENU_ITEMS } from "../constants/fetch/common";
import { AdvancedMenuItemI } from "../types/ui/menus";
import { useLocation, useNavigate } from "react-router-dom";
import { ModuleEnum } from "../enums/app";
import useRoute from './useRoute';
import { getRouteAfterRemovingQueryParams } from '../utils/urlHelpers';

const useHeaderElements = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getRoute, getCurrentLocationRoute } = useRoute();
  const { currentModule } = useAppSelector((state) => state.app);
  const selectedEchoCollection: InteractionCollectionI | undefined = useAppSelector((state) => state.echoCollections.selectedCollection);
  const isFetchKnowledgeContentMenuOpen: boolean = useAppSelector((state) => state.fetchKnowledge.isContentMenuOpen);
  const isProfileContentMenuOpen: boolean = useAppSelector((state) => state.profile.isContentMenuOpen);
  const isInOrgMode: boolean | undefined = useAppSelector((state) => state.user.isInOrgMode);
  const { currentUser, firstCollection } = useAppSelector((state) => state.user);
  const canCreateCollection = currentUser?.ui_settings?.create_collection;

  const echoCollectionsIconButtons: IconButtonI[] = useMemo(() => {

    if (selectedEchoCollection) {

      const adminRolesArray: string[] = Object.values(AdminRolesEnum);
      const isAdminRole = adminRolesArray.includes(selectedEchoCollection.role);

      return isAdminRole ?
        [{ icon: PlayCircle, handleClick: () => { dispatch(setIsRunModalOpen(true)) } }, { icon: Share2, handleClick: () => { dispatch(setIsEchoCollectionsShareModalOpen(true)) } }] :
        [{ icon: PlayCircle, handleClick: () => { dispatch(setIsRunModalOpen(true)) } },]
    } else {
      return [];
    }
  }, [dispatch, selectedEchoCollection]);

  const showBackButton: boolean = useMemo(() => {
    return (location.pathname.includes('/comments'))
  }, [location.pathname]);

  const showGlobalChatInput: boolean = useMemo(() => {
    return (
      currentModule === ModuleEnum.FETCH && (location.pathname !== getRoute(getRouteAfterRemovingQueryParams(ROUTE_FETCH_CHAT_ROOM)))
      || currentModule === ModuleEnum.FETCH && location.pathname === getRoute(getRouteAfterRemovingQueryParams(ROUTE_PROFILE))
      || location.pathname.includes('/comments')
      && !!isInOrgMode)
  }, [currentModule, isInOrgMode, location.pathname])

  const getFetchKnowledgeContentMenuItems = () => {
    if (!canCreateCollection) {
      return [];
    }
    const menuItems: AdvancedMenuItemI[] = [
      {
        ...ADD_CONTENT_MENU_ITEMS[0],
        onClick: () => { dispatch(setIsUploadModalOpen(true)); dispatch(setIsContentMenuOpen(false)) }
      },
      {
        ...ADD_CONTENT_MENU_ITEMS[1],
        onClick: () => {
          navigate(getRoute(ROUTE_FETCH_KNOWLEDGE_CONTENT_CREATE));
          dispatch(setIsContentMenuOpen(false))
        }
      },
    ]
    return menuItems;
  }

  const getProfileContentMenuItems = () => {
    if (!canCreateCollection) {
      return [];
    }
    const menuItems: AdvancedMenuItemI[] = [
      {
        ...ADD_CONTENT_MENU_ITEMS[0],
        onClick: () => { dispatch(setIsProfileUploadModalOpen(true)); dispatch(setIsProfileContentMenuOpen(false)) }
      },
      {
        ...ADD_CONTENT_MENU_ITEMS[1],
        onClick: () => {
          navigate(getRoute(ROUTE_FETCH_KNOWLEDGE_CONTENT_CREATE), {
            state: {
              previousUrl: getCurrentLocationRoute()
            }
          });
          dispatch(setIsProfileContentMenuOpen(false))
        }
      },
    ]
    return menuItems;
  }

  const getProfilePageIconButtons = () => {
    if (isInOrgMode && canCreateCollection) {
      return [
        {
          icon: CirclePlus,
          handleClick: () => { dispatch(setIsProfileContentMenuOpen(true)) },
          popoverConfig: {
            isOpen: isProfileContentMenuOpen,
            content: <AdvancedMenu menuItems={getProfileContentMenuItems()} />,
            handleClickOutside: () => { dispatch(setIsProfileContentMenuOpen(false)) }
          }
        },
      ]
    } else {
      return [];
    }
  }

  const getBackButtonOnClickNavigationRoute = () => {
    if (location.pathname.includes('/comments')) {
      return getRoute(ROUTE_PROFILE);
    }
    return '';
  }

  const iconBtnsConfigurations: HeaderIconBtnsConfigI[] = [
    {
      route: getRoute(ROUTE_FETCH_KNOWLEDGE),
      iconButtons: canCreateCollection
        ? [
          {
            icon: CirclePlus,
            handleClick: () => { dispatch(setIsContentMenuOpen(true)) },
            popoverConfig: {
              isOpen: isFetchKnowledgeContentMenuOpen,
              content: <AdvancedMenu menuItems={getFetchKnowledgeContentMenuItems()} />,
              handleClickOutside: () => { dispatch(setIsContentMenuOpen(false)) }
            }
          },
        ] : [],
    },
    {
      route: getRoute(ROUTE_FETCH_TAGS),
      iconButtons: [
        { icon: Plus, handleClick: () => { dispatch(setIsFetchAddNewCategoryBtnClicked(true)) } },
      ]
    },
    {
      route: getRoute(ROUTE_ECHO_METRICS),
      iconButtons: [
        { icon: FolderPlus, handleClick: () => { dispatch(setIsSaveModalOpen(true)) } },
        { icon: SlidersHorizontal, handleClick: () => { dispatch(setIsFilterModalOpen(true)) } },
      ]
    },
    {
      route: getRoute(ROUTE_ECHO_KNOWLEDGE),
      iconButtons: [
        { icon: FolderPlus, handleClick: () => { dispatch(setIsSaveModalOpen(true)) } },
        { icon: SlidersHorizontal, handleClick: () => { dispatch(setIsFilterModalOpen(true)) } },
      ]
    },
    {
      route: getRoute(ROUTE_ECHO_TAGS),
      iconButtons: [
        { icon: Plus, handleClick: () => { dispatch(setIsEchoAddNewCategoryBtnClicked(true)) } },
      ]
    },
    {
      route: getRoute(ROUTE_ECHO_COLLECTION, { collection_id: selectedEchoCollection?.filter_id! }),
      iconButtons: echoCollectionsIconButtons
    },
    {
      route: getRoute(ROUTE_FETCH_CHAT_ROOM),
      iconButtons: [{ icon: FilePenLine, handleClick: () => { dispatch(setIsNewSession(true)) } }]
    },
    {
      route: getRoute(ROUTE_INDEX_CHAT_ROOM),
      iconButtons: []/*[{ icon: History, handleClick: () => { dispatch(setIsSavedQueriesPanelOpen(true)) } }]*/
    },
    {
      route: getRoute(ROUTE_PROFILE),
      iconButtons: getProfilePageIconButtons()
    },
  ];

  const segmentedControlConfigurations: HeaderSegmentedControlConfigI[] = [
    // {
    //   route: ROUTE_FETCH_CHAT_ROOM,
    //   segmentedControlProps: {
    //     segments: [{
    //       id: 0,
    //       icon: MessageSquare,
    //       label: 'One chat',
    //       ref: useRef(null),
    //     }, {
    //       id: 1,
    //       icon: LayoutList,
    //       label: 'Feed',
    //       ref: useRef(null),
    //     }],
    //     defaultActiveIndex: 0,
    //     controlRef: useRef(null),
    //     handleChange: (selectedSegmentId: number) => {
    //       if (selectedSegmentId === 1) {
    //         navigate(ROUTE_FETCH_ORGANIZATION_FEED)
    //       }
    //     },
    //   }
    // },
    // {
    //   route: ROUTE_FETCH_ORGANIZATION_FEED,
    //   segmentedControlProps: {
    //     segments: [{
    //       id: 0,
    //       icon: MessageSquare,
    //       label: 'One chat',
    //       ref: useRef(null),
    //     }, {
    //       id: 1,
    //       icon: LayoutList,
    //       label: 'Feed',
    //       ref: useRef(null),
    //     }],
    //     defaultActiveIndex: 1,
    //     controlRef: useRef(null),
    //     handleChange: (selectedSegmentId: number) => {
    //       if (selectedSegmentId === 0) {
    //         navigate(ROUTE_FETCH_CHAT_ROOM)
    //       }
    //     },
    //   }
    // }
  ];


  return { iconBtnsConfigurations, segmentedControlConfigurations, showBackButton, showGlobalChatInput, getBackButtonOnClickNavigationRoute };
};

export default useHeaderElements;