import React, { useEffect, useState, useRef } from 'react';
import styles from './SegmentedControl.module.css';
import { LucideIcon } from 'lucide-react';
import { SegmentedControlI } from '../../types/ui/segmentedControl';

const SegmentedControl = (props: SegmentedControlI) => {

    const { segments, defaultActiveIndex = 0, controlRef, mainDivStylingClasses = '', segmentStylingClasses = '', labelStylingClasses = '', disabled = false, otherStylings,
        isHandleClickWithoutChangingSegment = false, handleChange, handleChangeOnDisable } = props;

    const [activeIndex, setActiveIndex] = useState<number>(defaultActiveIndex);

    const isComponentReadyRef = useRef<boolean>(false);

    const onInputChange = (segmentId: number, index: number) => {
        if (disabled && handleChangeOnDisable) {
            handleChangeOnDisable(segmentId);
        } else if (isHandleClickWithoutChangingSegment) {
            handleChange(segmentId);
        } else {
            setActiveIndex(index);
            handleChange(segmentId);
        }
    }

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        const { offsetWidth, offsetLeft } = activeSegmentRef.current!;
        const { style } = controlRef.current!;

        style.setProperty('--highlight-width', `${offsetWidth}px`);
        style.setProperty('--highlight-x-pos', `${offsetLeft}px`);
        style.setProperty('--active-segment-bg-color', otherStylings?.acitveSegmentBgColor ?? `var(--primary-color)`);
    }, [activeIndex, controlRef, handleChange, segments]);

    // Determine when the component is "ready"
    useEffect(() => {
        isComponentReadyRef.current = true;
    }, []);

    useEffect(() => {
        setActiveIndex(defaultActiveIndex);
    }, [defaultActiveIndex]);

    const renderIcon = (icon: LucideIcon) => {
        const Icon = icon;
        return (<Icon strokeWidth={1} size={14}></Icon>);
    }

    const renderImg = (img: string) => {
        return (
            <div>
                <img src={img} alt='segment-img' className='w-5' />
            </div>
        );
    }

    return (
        <div
            className={`${styles.controlsContainer} ${mainDivStylingClasses} ${disabled && !handleChangeOnDisable ? 'opacity-50 pointer-events-none' : ''}`}
            ref={controlRef}
        >
            <div className={`${styles.controls} ${isComponentReadyRef.current ? 'ready' : 'idle'} ${otherStylings?.inactiveSegmentBgColorClass ?? `bg-[#959595]`} bg-opacity-20`}>
                {segments.map((segment, index) => (
                    <div
                        key={segment.id}
                        className={`${styles.segment} ${segmentStylingClasses} ${index === activeIndex ? (otherStylings?.activeSegmentTextColorClass ?? 'text-white') : (otherStylings?.inactiveSegmentTextColorClass ?? 'text-slate-400')}`}
                        ref={segment.ref}
                        onClick={(event) => { event.preventDefault(); onInputChange(segment.id, index) }}
                    >
                        <input
                            type="radio"
                            value={segment.label}
                            id={segment.label}
                            onChange={() => { }}
                            checked={index === activeIndex}
                            disabled={disabled}
                        />
                        <div className='flex items-center px-2'>
                            {segment.icon && renderIcon(segment.icon)}
                            {segment.img && renderImg(segment.img)}
                            <label htmlFor={segment.label} className={`${labelStylingClasses}`}>
                                {segment.label}
                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SegmentedControl;
