import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Header.module.css';
import { ChevronLeft, Menu } from 'lucide-react';
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import Tabs from "../../Tabs/Tabs";
import IconButton from '../../IconButton/IconButton';
import { TabI } from '../../../types/ui/tabs';
import { HeaderIconBtnsConfigI, HeaderTabsConfigI, HeaderIconButtonI, HeaderSegmentedControlConfigI } from '../../../types/ui/header';
import { HEADER_TABS_CONFIGURATIONS } from '../../../constants/header';
import { ROUTE_ECHO_COLLECTIONS, ECHO_COLLECTION } from '../../../constants/routes';
import { classNames } from '../../../utils/styleHelpers';
import { setSelectedTabId } from '../../../state/features/echoCollections/echoCollectionsSlice';
import { cloneDeep } from 'lodash';
import { InteractionCollectionI } from '../../../types/echo/collections';
import { Popover } from 'react-tiny-popover';
import useHeaderElements from '../../../hooks/useHeaderElements';
import { SegmentedControlI } from '../../../types/ui/segmentedControl';
import SegmentedControl from '../../SegmentedControl/SegmentedControl';
import GlobalChatInput from '../../GlobalChatInput/GlobalChatInput';
import { OrganizationI } from '../../../types/organization';
import { DEFAULT_NETWORK_NAME } from '../../../constants/app';
import useRoute from '../../../hooks/useRoute';

interface HeaderI {
    setIsMobileSidebarOpen: (isOpen: boolean) => void
};

const Header = (props: HeaderI) => {
    const { setIsMobileSidebarOpen } = props;

    const { iconBtnsConfigurations, segmentedControlConfigurations, showBackButton, showGlobalChatInput, getBackButtonOnClickNavigationRoute } = useHeaderElements();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { getRoute, getCurrentLocationRoute } = useRoute();
    const selectedEchoCollection: InteractionCollectionI | undefined = useAppSelector((state) => state.echoCollections.selectedCollection);
    const isInOrgMode: boolean | undefined = useAppSelector((state) => state.user.isInOrgMode);
    const selectedOrganization: OrganizationI | undefined = useAppSelector((state) => state.organization.selectedOrganization);

    // set current network name depending on environment variable
    const currentNetowrkName = process.env.REACT_APP_UI_URL === 'https://platform.senso.ai' ? 'Senso' : DEFAULT_NETWORK_NAME;

    const getEchoCollectionsTabs = () => {
        if (selectedEchoCollection) {
            const headerTabs = cloneDeep(HEADER_TABS_CONFIGURATIONS).find((tabsConfig: HeaderTabsConfigI) => tabsConfig.routes.includes(ROUTE_ECHO_COLLECTIONS))!.tabs
            if (location.state?.isBackFromCustomer) {
                const newHeaderTabs = headerTabs.map((tab: TabI) => {
                    return {
                        ...tab,
                        current: tab.id === 2
                    }
                })
                dispatch(setSelectedTabId(2));
                return newHeaderTabs;
            } else {
                dispatch(setSelectedTabId(0));
                return headerTabs;
            }
        } else {
            dispatch(setSelectedTabId(0));
            return [];
        }
    };

    const tabs: TabI[] = useMemo(() => {
        if (location.pathname.includes(getRoute(ECHO_COLLECTION))) {
            return getEchoCollectionsTabs();
        } else {
            // This else part can be used to switch between tabs without any changes to handleTabClick function below, if the url changes with the tab change
            const headerTabs = cloneDeep(HEADER_TABS_CONFIGURATIONS).find((tabsConfig: HeaderTabsConfigI) => tabsConfig.routes.includes(location.pathname))?.tabs || []
            const newHeaderTabs = headerTabs.map((tab: TabI) => {
                return {
                    ...tab,
                    current: tab.href === location.pathname
                }
            })
            return newHeaderTabs;
        }
    }, [location.pathname, location.state?.isBackFromCustomer, selectedEchoCollection]);

    // Need to modify this function to update state, if url does not change with the tab change
    const handleTabClick = (tabId: number) => {
        if (location.pathname.includes(ECHO_COLLECTION)) {
            dispatch(setSelectedTabId(tabId));
        }
    }

    const iconButtons: HeaderIconButtonI[] = useMemo(() => {
        return iconBtnsConfigurations.find((iconBtnsConfig: HeaderIconBtnsConfigI) => iconBtnsConfig.route === getCurrentLocationRoute())?.iconButtons || [];
    }, [iconBtnsConfigurations, location.pathname, location.search]);

    const segmentedControlProps: SegmentedControlI | undefined = useMemo(() => {
        return cloneDeep(segmentedControlConfigurations).find((segmentedControlConfig: HeaderSegmentedControlConfigI) => segmentedControlConfig.route === location.pathname)?.segmentedControlProps
    }, [segmentedControlConfigurations, location.pathname]);

    return (
        <div className={classNames('h-[80px] lg:h-[60px] border-b flex items-center shrink-0 relative', `${styles.headerBar}`)}>
            {!showBackButton ?
                <div className='absolute pl-6 text-zinc-950 text-base font-normal w-[200px] truncate'>
                    {isInOrgMode ? selectedOrganization?.name : currentNetowrkName}
                </div>
                :
                <div className='absolute pl-6'>
                    <IconButton icon={ChevronLeft} text='Back' btnStylingClasses='font-semibold text-base !p-0' handleClick={() => navigate(getBackButtonOnClickNavigationRoute())} />
                </div>
            }
            {
                showGlobalChatInput &&
                <GlobalChatInput />
            }
            <div className='lg:hidden px-5'>
                <IconButton icon={Menu} showBorder btnStylingClasses='max-sm:w-7 max-sm:h-7' handleClick={() => setIsMobileSidebarOpen(true)} />
            </div>
            {segmentedControlProps ?
                <div className='absolute left-1/2 -translate-x-1/2 lg:pl-[70px]'>
                    <SegmentedControl {...segmentedControlProps} />
                </div>
                :
                <div className='absolute left-1/2 -translate-x-1/2 self-end'>
                    <Tabs tabs={tabs} handleTabClick={handleTabClick} />
                </div>
            }
            <div className={`${styles.iconBtnContainer} max-lg:!pr-5 gap-2 lg:gap-[15px]`}>
                {iconButtons.map((iconBtn: HeaderIconButtonI, index) => (
                    <>
                        {!iconBtn.popoverConfig ?
                            <IconButton key={index} {...iconBtn} btnStylingClasses={`max-sm:w-7 max-sm:h-7 ${iconBtn.btnStylingClasses ? iconBtn.btnStylingClasses : ''}`} />
                            :
                            <Popover
                                isOpen={iconBtn.popoverConfig.isOpen}
                                positions={'bottom'}
                                content={iconBtn.popoverConfig.content}
                                align='end'
                                onClickOutside={iconBtn.popoverConfig.handleClickOutside}
                            >
                                <IconButton key={index} {...iconBtn} btnStylingClasses={`max-sm:w-7 max-sm:h-7 ${iconBtn.btnStylingClasses ? iconBtn.btnStylingClasses : ''}`} />
                            </Popover>
                        }
                    </>
                ))}
            </div>
        </div>
    );
};

export default Header;
